import merge from 'lodash/merge';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser, currentUserShowSuccess } from '../../ducks/user.duck';
import { fetchStripeAccount } from '../../ducks/stripeConnectAccount.duck';

// ================ Action types ================ //

export const SAVE_CONTACT_DETAILS_REQUEST = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_REQUEST';
export const SAVE_COMPANY_DETAILS_REQUEST = 'app/ContactDetailsPage/SAVE_COMPANY_DETAILS_REQUEST';

export const SAVE_CONTACT_DETAILS_SUCCESS = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_SUCCESS';
export const SAVE_EMAIL_ERROR = 'app/ContactDetailsPage/SAVE_EMAIL_ERROR';
export const SAVE_PHONE_NUMBER_ERROR = 'app/ContactDetailsPage/SAVE_PHONE_NUMBER_ERROR';
export const SAVE_BILLING_ADDRESS_ERROR = 'app/ContactDetailsPage/SAVE_BILLING_ADDRESS_ERROR';
export const SAVE_BUILDING_ERROR = 'app/ContactDetailsPage/SAVE_BUILDING_ERROR';
export const SAVE_CIF_ERROR = 'app/ContactDetailsPage/SAVE_CIF_ERROR';

export const SAVE_CONTACT_DETAILS_CLEAR = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_CLEAR';

export const RESET_PASSWORD_REQUEST = 'app/ContactDetailsPage/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'app/ContactDetailsPage/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'app/ContactDetailsPage/RESET_PASSWORD_ERROR';

// ================ Reducer ================ //

const initialState = {
  saveEmailError: null,
  savePhoneNumberError: null,
  saveContactDetailsInProgress: false,
  contactDetailsChanged: false,
  resetPasswordInProgress: false,
  resetPasswordError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_CONTACT_DETAILS_REQUEST:
      return {
        ...state,
        saveContactDetailsInProgress: true,
        saveEmailError: null,
        savePhoneNumberError: null,
        contactDetailsChanged: false,
      };
    case SAVE_CONTACT_DETAILS_SUCCESS:
      return { ...state, saveContactDetailsInProgress: false, contactDetailsChanged: true };
    case SAVE_EMAIL_ERROR:
      return { ...state, saveContactDetailsInProgress: false, saveEmailError: payload };
    case SAVE_PHONE_NUMBER_ERROR:
      return { ...state, saveContactDetailsInProgress: false, savePhoneNumberError: payload };

    case SAVE_CONTACT_DETAILS_CLEAR:
      return {
        ...state,
        saveContactDetailsInProgress: false,
        saveEmailError: null,
        savePhoneNumberError: null,
        contactDetailsChanged: false,
      };

    case RESET_PASSWORD_REQUEST:
      return { ...state, resetPasswordInProgress: true, resetPasswordError: null };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, resetPasswordInProgress: false };
    case RESET_PASSWORD_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, resetPasswordInProgress: false, resetPasswordError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveContactDetailsRequest = () => ({ type: SAVE_CONTACT_DETAILS_REQUEST });
export const saveContactDetailsSuccess = () => ({ type: SAVE_CONTACT_DETAILS_SUCCESS });
export const saveContactDetailsClear = () => ({ type: SAVE_CONTACT_DETAILS_CLEAR });

// ================ Thunks ================ //

const requestSaveCompanyDetails = params => (dispatch, getState, sdk) => {
  return sdk.currentUser
    .updateProfile(
      { privateData: { companyDetails: { companyPublicDetails: params } } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      // const entities = denormalisedResponseEntities(response);
      // if (entities.length !== 1) {
      //   throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      // }

      // const currentUser = entities[0];
      return response;
    })
    .catch(e => {
      // dispatch(savePhoneNumberError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

export const companyDetails = params => (dispatch, getState, sdk) => {
  console.log('params:------------------', params);
  dispatch(requestSaveCompanyDetails(params));

  const {
    email,
    currentEmail,
    phoneNumber,
    currentPhoneNumber,
    currentPassword,
    billingAddress,
    currentBillingAddress,
    building,
    currentBuilding,
    cif,
    currentCif,
    firstName,
    lastName,
  } = params;
  console.log(params);
};

export const loadData = () => (dispatch, getState, sdk) => {
  console.log(
    '--------------------------------------laoding state here---------------------------------------------------',
    getState
  );
  // dispatch(fetchStripeAccount());
  return dispatch(fetchCurrentUser()).then(response => {
    const currentUser = getState().user.currentUser;
    console.log('currentUser:', currentUser);
    if (currentUser && currentUser.stripeAccount) {
      dispatch(fetchStripeAccount());
    } else {
      // dispatch(fetchStripeAccount());
      return response;
    }
  });
};
